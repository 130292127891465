import React from 'react';

const Mobile_part = () => {
    return (
        <section id="xAd5I3BpyESsLeUY" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0, marginTop: '-1px' }}>
              <div id="Na8LLn5mr85bo1ZY" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
            <div id="gf6mDoiVyioneUmw" style={{ zIndex: 0 }}>
                <div id="zin5AkOlkYYg9OoK" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="LSbhxeqX3kPsFWiU" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                        <div id="BqOSNNpFjnMGy09a" style={{ backgroundColor: '#8595a4', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        </div>
                    </div>
                </div>
            </div>
            <div id="nhxRSzIVw5Nk7IcU" style={{ zIndex: 1, left: 'max(0px, calc(50% - 63.19688613px))', right: 'max(0px, calc(50% - 63.19688613px))', top: 0, position: 'absolute', transform: 'translateY(0%)' }}>
                <div id="LqA3HXmzQtgvSmiq" style={{ paddingTop: '109.43396226%', transform: 'rotate(0deg)' }}>
                    <div id="raVSO29jwTdlOnG9" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                        <div id="UzPvNMRtWS70Zm0Q" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                            <div id="w4UiyGLyQ4HeaQLl" style={{ transform: 'scale(-1, -1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                <div id="F92IABPmzSjWAAZp" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                    <img src="images/be6b997c8f608cc33958055588982152.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Vt7GJAalGKZQaKij" style={{ zIndex: 2, left: '-25.22039217px', right: 'max(0px, calc(100% - 195.31158205px))', top: 0, position: 'absolute', transform: 'translateY(-14.72166892%)' }}>
                <div id="oU5RK4r34U9u257w" style={{ paddingTop: '100%', transform: 'rotate(-1.60034332deg)' }}>
                    <div id="STcb17VtaspeLji5" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                        <div id="gCkFo9xjt0tbO8Sp" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                            <div id="PWBkkKVmw5moe5z1" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                <div id="JcRIFUIEfnOYZmiJ" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                    <img src="images/903771a4f565794126b1f3c670cf5826.png" loading="lazy" srcset="images/3c32d65d8b1e135045eb59c3ee143152.png 200w, images/903771a4f565794126b1f3c670cf5826.png 400w" sizes="(max-width: 375px) 84.95236654vw, (min-width: 375.05px) and (max-width: 480px) 66.36903636vw, (min-width: 480.05px) and (max-width: 768px) 25.8062452vw, (min-width: 768.05px) and (max-width: 1024px) 19.3546839vw, (min-width: 1024.05px) 14.29806604vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="jmdxaLRGNSiiyoy3" style={{ zIndex: 3, left: 'max(0px, calc(50% - 93.29351107px))', right: 'max(0px, calc(50% - 93.29351107px))', top: 0, position: 'absolute', transform: 'translateY(0%)' }}>
                <div id="xzzPR98mNscaPqe4" style={{ paddingTop: '105.74102928%', transform: 'rotate(-180deg)' }}>
                    <div id="jBPYB7IniEeaUiRl" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                        <div id="XV2GGs2SspmhRTMj" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                            <div id="QfzrZxcflvvMMX5d" style={{ transform: 'scale(-1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                <div id="ZnWjvVlnhanPjlf6" style={{ width: 'calc(101.22769266% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                    <img src="images/994b1c86bba1291ca59e3802db015f80.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '49.39359842% 50%', transform: 'translate(-49.39359842%, -50%) rotate(0deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="uTRwj8bL6JksHI2f" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
        <div id="mjsm1c2t5OdyMyGs">
            <div id="URfuaVxzUJAISptL" style={{ display: 'grid', position: 'relative' }}>
                <div id="ai3BUc0MlGYfDdfg" style={{ zIndex: 9 }}>
                    <div id="zb0YHqvuzc3DX9VV" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(90deg)' }}>
                        <div id="T2CZnVwFOZ3UruZu" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                            <p id="sViCrNXMzHQFBGDm" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.36356819em', textAlign: 'center' }}>
                                <span id="Hvl21x6fRhxkPdN8" style={{ color: '#ffffff' }}>I’m watching you</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="luJhK9hZ34wkaHFE" style={{ zIndex: 8 }}>
                    <div id="b2ukPT2oew02tmDw" style={{ paddingTop: '114.19080251%', transform: 'rotate(0deg)' }}>
                        <div id="BZez8MNEqH4Xq4My" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <div id="xqANtGfF5GIzmce9" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                <div id="y1WIhrMEfLyd51dw" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                    <div id="qa3oVqqCHFrcZ38L" className="scale_rotated_fill_v2" data-aspect-ratio="1.14190803" style={{ width: 'calc(118.43003413% * max(1, var(--scale-fill, 1)))', height: 'calc(87.57272723% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                        <img src="images/873ccb7d840c9adc297395d8e3ca75cf.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 49.58697102%', transform: 'translate(-50%, -49.58697102%) rotate(90deg)' }} alt="Your alt text here" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="ifAVsruHwz9kjPFD">
         <div id="jucDbtwp2HT4sjMK" style={{ display: 'grid', position: 'relative' }}>
         <div id="Fad6vMeUbtZ2He1b" style={{ zIndex: 5 }}>
      <div id="S0WUMOIW9huOlCdC" style={{ paddingTop: '206.18556701%', transform: 'rotate(0deg)' }}>
        <div id="EAMnOQp72sYjDRjV" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
          <a id="LS9qaWnyvb3K2g8d" target="_blank" rel="noopener" href="https://t.me/+GdJZU7dPNGYwMmMx" style={{ pointerEvents: 'all' }} data-interstitial-link="">
            <div id="eu6pmBbWIEI4JS59" style={{ width: '100%', height: '100%', opacity: '1.0' }}>
              <div id="QST1ei30OksaRSOC" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                <div id="yQMKwbnzPRDh28Xt" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: '1.0', animation: 'pulse 1.5s ease-in-out infinite' }}>
                  <img src="images/f2ce7ea475c58112ca05c127ee79f861.svg" alt="Cell Phone Icon" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
         </div>
         <div id="j0HYvn6oMCOBBfoM" style={{ zIndex: 10 }}>
      <div id="rnIldOKP0juqdTlP" style={{ paddingTop: '99.875%', transform: 'rotate(0deg)' }}>
        <div id="rQkKsbzowan0RB3Q" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
          <a id="EzBbM1ApDNePI9Pp" target="_blank" rel="noopener" href="#" style={{ pointerEvents: 'all' }} data-interstitial-link="">
            <div id="WzWvogZ8cnDbnYvX" style={{ width: '100%', height: '100%', opacity: '1.0' }}>
              <div id="TNUMlrQpjdZDauFj" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                <div id="sLPetLGiwl0nT8Z8" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: '1.0', animation: 'pulse 1.5s ease-in-out infinite' }}>
                  <img src="images/441261d91dd900656131ffb6f9072a11.svg" alt="Word Logo" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
         </div>
         <div id="jV7xp5mlXLnim5SW" style={{ zIndex: 12, pointerEvents: 'none' }}>
      <div id="zd7n0ZJNVVJIe5mx" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
        <div id="b7TKuuWORkJsMtVH" style={{ opacity: '1.0', display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
          <p id="BojhAQzNFsnZ5dTl" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.35933889em', textAlign: 'center' }}>
            <a id="v3y4Ce3hHJApsjM0" target="_blank" rel="noopener" href="#" style={{ textDecorationLine: 'underline', color: '#ffffff', pointerEvents: 'all' }} data-interstitial-link="">FOLLOW </a>
          </p>
          <p id="i7QYNgwmQawOcVsw" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.36955033em', textAlign: 'center' }}>
            <a id="Lxua5ZQUf1rtNPbs" target="_blank" rel="noopener" href="#" style={{ textDecorationLine: 'underline', color: '#ffffff', pointerEvents: 'all' }} data-interstitial-link="">ME</a>
          </p>
        </div>
      </div>
         </div>
         <div id="tLJ0LZfSjHPocLPP" style={{ zIndex: 11, pointerEvents: 'none' }}>
      <div id="pWu8Y74cUyRtjneQ" style={{ paddingTop: '100.12515645%', transform: 'rotate(0deg)' }}>
        <div id="hJ0V7y9PoIklAksZ" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
          <div id="miAKleSqsLtYcsu2" style={{ width: '100%', height: '100%', opacity: '1.0' }}>
            <div id="eCnuEX35WG0F0nqt" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
              <div id="Ujv0WeaVvV6Uum6Y" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: '1.0', animation: 'pulse 1.5s ease-in-out infinite' }}>
                <img src="images/243e343829198f5a5127710e4d9c5955.svg" alt="Telegram App Icon" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
         </div>
         <div id="LRd01ImKuToY4rAr" style={{ zIndex: 13, pointerEvents: 'none' }}>
      <div id="VlycuXW2Aay68lh5" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
        <div id="WRlcdnAEtUh31gCa" style={{ opacity: '1.0', display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
          <p id="FzBdJLvypbJiHGlj" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.37493985em', textAlign: 'center' }}>
            <a id="CB8vsAwjrFQrwC52" target="_blank" rel="noopener" href="#" style={{ textDecorationLine: 'underline', color: '#ffffff', pointerEvents: 'all' }} data-interstitial-link="">JOIN </a>
          </p>
          <p id="FVi6i8bdRiWcbFCi" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.3815535em', textAlign: 'center' }}>
            <a id="qgTLUDg7DsHsCci1" target="_blank" rel="noopener" href="#" style={{ textDecorationLine: 'underline', color: '#ffffff', pointerEvents: 'all' }} data-interstitial-link="">HAMILY</a>
          </p>
        </div>
      </div>
         </div>
         <div id="hNFBRSxVXUbtk2I7" style={{ zIndex: 6 }}>
      <div id="TWmdAiRnVrzk4Moy" style={{ paddingTop: '84%', transform: 'rotate(0deg)' }}>
        <div id="ml8Q6lcUfi58TyJQ" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
          <a id="GokLIxEfease23L1" target="_blank" rel="noopener" href="#" style={{ pointerEvents: 'all' }} data-interstitial-link="">
            <div id="FncemqXqvJJ7XZLM" style={{ width: '100%', height: '100%', opacity: '1.0' }}>
              <div id="PMB3JHElJGc6xt5D" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                <div id="LTR0Csk4hefwNoWb" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: '1.0', animation: 'pulse 1.5s ease-in-out infinite' }}>
                  <img src="images/edd11828feaafe6fb37b3241ae38a96e.svg" alt="Solana icon" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
         </div>
         <div id="xEFtqkvJimhOAf9Q" style={{ zIndex: 14, pointerEvents: 'none' }}>
      <div id="F9R11T8BZyGjLcFk" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
        <div id="rBXcVtWa2EcJNA2y" style={{ opacity: '1.0', display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
          <p id="y2jc01O0MMxzoalF" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.39426547em', textAlign: 'center' }}>
            <a id="PAOpXpPDKgaGYi61" target="_blank" rel="noopener" href="#" style={{ textDecorationLine: 'underline', color: '#ffffff', pointerEvents: 'all' }} data-interstitial-link="">BUY</a>
          </p>
          <p id="FN80McT3KQt6vjrh" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.39426547em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
            <a id="Itp06I0UrPptTI7N" target="_blank" rel="noopener" href="#" style={{ textDecorationLine: 'underline', color: '#ffffff', pointerEvents: 'all' }} data-interstitial-link="">$HAMI</a><br />
          </p>
        </div>
      </div>
         </div>
         <div id="uWv7nrbTMClyNHiT" style={{ zIndex: 4, pointerEvents: 'none' }}>
         <div id="NRGw7ZHmHsA9U7To" style={{ paddingTop: '88.21211012%', transform: 'rotate(0deg)' }}>
            <div id="B1yKY6nqiCYaGgOd" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
            <div id="jR6yRjyVv7LGKSda" style={{ width: '100%', height: '100%', opacity: '1.0' }}>
                <div id="LMe4HmOhneFYfwj0" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                <div id="VcnmwZDs3XkOUWVb" style={{ width: 'calc(104.46963212% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: '1.0', animation: 'pulse 1.5s ease-in-out infinite' }}>
                    <img src="images/873ccb7d840c9adc297395d8e3ca75cf.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '47.86079838% 50%', transform: 'translate(-47.86079838%, -50%) rotate(0deg)' }} />
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>


         </div>
      </div>

        </div>
        
        </section>
    );
}

export default Mobile_part;
