import React from 'react';

const Social_link = () => {
    return (
        <section id="XSRyK5FSDeNMbhO5"
            style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0, marginTop: '-1px' }}>
            <div id="N7vhRV8Jz10fVQAz"
                style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
                <div id="FxtKSi8NK6hnvUP7" style={{ zIndex: 0 }}>
                    <div id="S7sBOvNElUPa7QNa"
                        style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                        <div id="lSix7zRDGInuUlqM" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                            <div id="ZATCjn9TU8ajBzmm"
                                style={{ backgroundColor: '#8595a4', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="eq3r0sZLucEge2y2" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
                <div id="dIhpua6jiuMCdVu6" style={{ zIndex: 7 }}>
                    <div id="sfmyskw6XSpr7Gns"
                        style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                        <div id="WY3szI2lxxlQIr9v"
                            style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                            <p id="RTObhY5esNjbKtpu"
                                style={{ color: '#000000', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.39285652em', textAlign: 'center' }}>
                                <span id="d8Nx3z01xiMa9UPj" style={{ color: '#ffffff' }}>$HAMI LINKS</span><br /></p>
                        </div>
                    </div>
                </div>
                <div id="xdYKpE9v7nsVFKjE" style={{ zIndex: 10 }}>
                    <div id="yAr2ZfCWO8ginhlf" style={{ paddingTop: '99.875%', transform: 'rotate(0deg)' }}>
                        <div id="vYbMpxCcJR4zgtHe" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <a id="k0DxN8pIxqJtnIYa" target="_blank" rel="noopener"
                                href="#" style={{ pointerEvents: 'all' }}
                                data-interstitial-link="">
                                <div id="Tw0ENj4UbnaIKdtI" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                    <div id="B7cNzkaDdUYwq8pc"
                                        style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                        <div id="acAAHfmFPGo5EOsM"
                                            style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                            <img src="images/441261d91dd900656131ffb6f9072a11.svg" alt="Word Logo"
                                                loading="lazy"
                                                style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="ov2Tq2RqLpdeUUGb" style={{ zIndex: 8 }}>
                    <div id="rzl6U7xgHXelDUO3"
                        style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                        <div id="pT4TjINN47BsRnfC"
                            style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column',justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                            <p id="QflygA5lWyqVBzCA"
                                style={{ color: '#000000', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.37555016em', textAlign: 'center' }}>
                                <span id="D2V3J4TyQVMbPokt" style={{ color: '#000000' }}>Follow Us On X</span><br /></p>
                        </div>
                    </div>
                </div>
                <div id="VKhIcLIQKe10EASv" style={{ zIndex: 11 }}>
                    <div id="vB04JdHIwViUcpIp" style={{ paddingTop: '100.12515645%', transform: 'rotate(0deg)' }}>
                        <div id="nZxh1Qs878imncxg" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <a id="k94iar4GI7A2h5ui" target="_blank" rel="noopener"
                                href="https://t.me/+GdJZU7dPNGYwMmMx" style={{ pointerEvents: 'all' }}
                                data-interstitial-link="">
                                <div id="ha4XMDKZKDCWwEjS" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                    <div id="HvC8SPI5gjbzWsNg"
                                        style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                        <div id="ut7z5EeoFEgUGXU8"
                                            style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                            <img src="images/243e343829198f5a5127710e4d9c5955.svg"
                                                alt="Telegram App Icon" loading="lazy"
                                                style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="Ab1u6HWZKVxw3NyV" style={{ zIndex: 9 }}>
                    <div id="tsG10qbVcedzYPQD"
                        style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                        <div id="kpngXEIJ4JY1VI8F"
                            style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                            <p id="AmGfHErTiX6FlKUK"
                                style={{ color: '#000000', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.37555016em', textAlign: 'center' }}>
                                <span id="pjnvnXHmO1bzRSJP" style={{ color: '#000000' }}>Join Telegram</span><br /></p>
                        </div>
                    </div>
                </div>
                <div id="C2QPJyLAEOxKhpFS" style={{ zIndex: 1 }}>
                    <div id="mHMDMmOl2ITeHiSO" style={{ paddingTop: '32.67080316%', transform: 'rotate(0deg)' }}>
                        <div id="jwyYkYjPm1p8xoNi" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <a id="ocpXabMxU2D7k4ww" target="_blank" rel="noopener"
                                href="https://birdeye.so/token/4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ"
                                style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                <div id="vyX093ePFOTX5PDs" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                    <div id="Syvp7XMtTi7izaHu"
                                        style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                        <div id="uFH8z77X8NxUCtX6"
                                            style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(152.37932639% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                            <img src="images/4630c277a580f4db2f5ac2e297bbbef2.png" loading="lazy"
                                                style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 46.62508311%', transform: 'translate(-50%, -46.62508311%) rotate(0deg)' }} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="yva0jgKDKPwDiz2k" style={{ zIndex: 2 }}>
                    <div id="JRy6sEwXfrdNj7KC" style={{ paddingTop: '27.78276793%', transform: 'rotate(0deg)' }}>
                        <div id="Q25eP9sOOVEgzwjl" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <a id="mRk8oLMK3Krhnw3s" target="_blank" rel="noopener"
                                href="https://www.dextools.io/app/en/solana/pair-explorer/84Nu43GsHLaCDuVSvgiTwpQDTNhYejKFEkJmeXBFJmqQ"
                                style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                <div id="VTuekLzIceB3dCyO" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                    <div id="urdk9MjKYo4chity"
                                        style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                        <div id="qFFnFvTmGnkfJkNE"
                                            style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(103.39255707% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                            <img src="images/cd6fdb0f6eabeb3e3e64400a00a52a6d.png" loading="lazy"
                                                srcset="images/44c4650505370a7c6c37f151d7c98848.png 335w, images/cd6fdb0f6eabeb3e3e64400a00a52a6d.png 502w"
                                                sizes="(max-width: 375px) 69.57134369vw, (min-width: 375.05px) and (max-width: 480px) 54.35261226vw, (min-width: 480.05px) and (max-width: 768px) 33.97038266vw, (min-width: 768.05px) and (max-width: 1024px) 25.477787vw, (min-width: 1024.05px) 19.09901456vw"
                                                style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="iPsOz0BV2hrS7orG" style={{ zIndex: 3 }}>
                    <div id="iLiaEo2RSoqR6XZc" style={{ paddingTop: '20.90032154%', transform: 'rotate(0deg)' }}>
                        <div id="GsDhC8e8QL4OzPiP" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <a id="gAOUsisveZMQ6UwI" target="_blank" rel="noopener"
                                href="https://dexscreener.com/solana/84nu43gshlacduvsvgitwpqdtnhyejkfekjmexbfjmqq"
                                style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                <div id="RLOQz0aDq3Hu3HpJ" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                    <div id="Zkn27ywgY1GEEE4p"
                                        style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                        <div id="rl1zZTJTHHqIV3iR"
                                            style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                            <img src="images/069ce792efba969ec28c1f9742486b4b.png" loading="lazy"
                                                srcset="images/a9626a4ed7a7b1a47461900d20fc800e.png 374w, images/069ce792efba969ec28c1f9742486b4b.png 560w"
                                                sizes="(max-width: 375px) 86.64453732vw, (min-width: 375.05px) and (max-width: 480px) 67.69104478vw, (min-width: 480.05px) and (max-width: 768px) 42.30690299vw, (min-width: 768.05px) and (max-width: 1024px) 31.73017724vw, (min-width: 1024.05px) 23.78601866vw"
                                                style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="kahfu8N4ZCfFFb36" style={{ zIndex: 4 }}>
                    <div id="MHG2qlpQXrQM3Qti" style={{ paddingTop: '84.43804035%', transform: 'rotate(0deg)' }}>
                        <div id="EqoL2OyudZSkrvXh" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <div id="bXz5k2GRKKMsINuI" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                <div id="wmCos8pJ30c7UhLm"
                                    style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                    <div id="mIwocyNVv1GD89Q8"
                                        style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                        <img src="images/873ccb7d840c9adc297395d8e3ca75cf.png" loading="lazy"
                                            style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                       </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="FED3OR89ZuHikYwN" style={{ zIndex: 5 }}>
                    <div id="u6p8CjF9yRqJQ1C3" style={{ paddingTop: '99.6835443%', transform: 'rotate(0deg)' }}>
                        <div id="uqDf9mJJbjjZx5R6" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                            <div id="ZDYF98o7X8KZNSx4" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                <div id="qnLF8uzvFpLSgNr8"
                                    style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                    <div id="OwhRm98EPc9QhMO3"
                                        style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                        <img src="images/916fb6d536b8610aaa801b53b4b4ef20.png" loading="lazy"
                                            style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="QjgAdPfc9soq6Q3a" style={{ zIndex: 6 }}>
                    <div id="EM6IZAK2RuGlBfgT"
                        style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                        <div id="p5ifnIlOWYgWJPgL"
                            style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                            <p id="HBbxilfPR0PHGSra"
                                style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: 1.38594859, textAlign: 'center' }}>
                                <span id="RFroOn2p4HMSceIQ" style={{ color: '#ffffff' }}>CA: 4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Social_link;

                                   


