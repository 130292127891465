import React from 'react';

const About = () => {
  return (
    <section
      id="hOotsBpkt8atLxSs"
      style={{
        position: 'relative',
        overflow: 'hidden',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 100rem auto',
        zIndex: 0,
        marginTop: '-1px'
      }}
    >
     <div id="ANKLT5kxx46cEeQl" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
      <div id="GILKTLY1u218lnE8" style={{ zIndex: 0 }}>
        <div id="p4TWq7MC1Mvjuq3W" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
          <div id="RheB7M4J3hgVO13h" style={{ width: '100%', height: '100%', opacity: 1 }}>
            <div id="lOoZ0LLVosaX2ccr" style={{ backgroundColor: '#8595a4', opacity: 1, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }} />
          </div>
        </div>
      </div>
    </div>
    <div id="CDCvnTsrvbYSNd9N" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
    <div id="MuFuxrRyzTXDZ5Ky" style={{ zIndex: 2 }}>
      <div id="F9X2SUJkV7ph93FN" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
        <div id="BKDxxwUrctSXDPSY" style={{ writingMode: 'horizontal-tb', opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
          <p id="MkJQ2ytkwrkTmOFm" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.67142738em', textAlign: 'center', letterSpacing: '-0.04em' }}>
            <span id="aousd14xKBHwQAiE" style={{ textDecorationLine: 'underline', color: '#ffffff' }}>About $HAMI</span><br />
          </p>
          <p id="GI0T5gnIQsVd2o62" style={{ color: '#ffffff', fontFamily: 'YAFdJjTk5UU-0', lineHeight: '1.64898587em', textAlign: 'center', letterSpacing: '-0.02em', whiteSpace: 'pre' }}>
            <br />
          </p>
          <p id="cDv0P8Av3z2P0yoC" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.64898587em', textAlign: 'center', letterSpacing: '-0.02em' }}>
            <span id="IfsAzUdF1zLClc1m" style={{ color: '#ffffff' }}>Introducing </span><span id="rfKUXyepwfmuKeK9" style={{ color: '#ffffff', fontWeight: 700 }}>$HAMI</span><span id="l66Uv7yiCvclr1lp" style={{ color: '#ffffff' }}> the latest sensation in the world of meme coins on the SOLANA blockchain!</span><br />
          </p>
          <p id="MyqZ6URoSO51Totn" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.64898587em', textAlign: 'center', letterSpacing: '-0.02em', whiteSpace: 'pre' }}>
            <br />
          </p>
          <p id="DVgnCPpCU0cRFVm6" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.64898587em', textAlign: 'center', letterSpacing: '-0.02em' }}>
            <span id="SoLbjqaD738VOBms" style={{ color: '#ffffff' }}>Similar to the charm of Shiba Inu on Ethereum, </span><span id="aI1XZuvalpLCbuN4" style={{ color: '#ffffff', fontWeight: 700 }}>$HAMI</span><span id="zH0gWJueRn4HbjYL" style={{ color: '#ffffff' }}> brings a delightful twist with its adorable hamster-themed allure. Dive into the whimsical universe of </span><span id="LU7hrgZi5W8DJV2R" style={{ color: '#ffffff', fontWeight: 700 }}>$HAMI</span><span id="cV1y9IHg9xEfeNes" style={{ color: '#ffffff' }}>, where crypto merges with humor, and embark on an exciting journey toward the next big meme coin sensation on SOLANA.</span><br />
          </p>
          <p id="SQzseiE94WnwLA4f" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.64898587em', textAlign: 'center', letterSpacing: '-0.02em', whiteSpace: 'pre' }}>
            <br />
          </p>
          <p id="KH78ZS14hoxUzLDU" style={{ color: '#000000', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.64898587em', textAlign: 'center', letterSpacing: '-0.02em' }}>
            <span id="njMIPMwYHvuOcY4U" style={{ color: '#ffffff' }}>Join the </span><span id="Xn6OTz9ytf4C5jKi" style={{ color: '#ffffff', fontWeight: 700 }}>$HAMI</span><span id="UmFnoq9r4of2UZ6j" style={{ color: '#ffffff' }}> community today and witness the rise of a new star in the meme coin universe on SOLANA. Embrace the charm, embrace the humor, and embrace the future of crypto with </span><span id="JS6IgmGsbJLvJdnA" style={{ color: '#ffffff', fontWeight: 700 }}>$HAMI</span><br />
          </p>
          <p id="cGSNV06iuPgujfX4" style={{ color: '#000000', fontFamily: 'YAFdJjTk5UU-0', lineHeight: '1.64898587em', textAlign: 'center', whiteSpace: 'pre' }}>
            <br />
          </p>
        </div>
      </div>
    </div>
    <div id="IvQhnbWVVTiRMZ4L">
      <div id="EJWByrR8TQBJuVrV" style={{ display: 'grid', position: 'relative' }}>
        <div id="bUycQYLn6OiMnYA3" style={{ zIndex: 1 }}>
          <div id="n0zrLkXCju8He8oB" style={{ paddingTop: '137.21638074%', transform: 'rotate(0deg)' }}>
            <div id="FP5zG9dBd33CycO5" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>

     <svg
      id="wBtbdrFhIrccCFEA"
      viewBox="0 0 361.4 495.9"
      style={{
        width: '100%',
        height: '100%',
        opacity: 1.0,
        overflow: 'hidden',
        position: 'absolute',
        top: '0%',
        left: '0%',
        background: "url('data:image/png;base64,')"
      }}
    >
      <g id="n2yvzIoSJcLPAAau" style={{ transform: 'scale(1, 1)' }}>
        <path
          id="U6U17Vb2kHjDBwL8"
          d="M247.8999939,134.5 C216.8999939,134.5 188.69999313,147 168.19999695,167.29999924 L126.90000153,126 C140.00000191,112.60000038 148.20000076,94.29999924 148.20000076,74.09999847 C148.19999695,33.20000076 114.90000153,0 74.09999847,0 C33.29999542,0 0,33.20000076 0,74.09999847 C0,114.89999771 33.20000076,148.19999695 74.09999847,148.19999695 C91.39999771,148.19999695 107.29999924,142.19999695 120,132.19999695 L161.90000153,174.09999847 C144.80000114,193.99999809 134.50000191,219.79999924 134.50000191,247.90000153 C134.50000191,279.50000191 147.50000191,308.20000076 168.50000191,328.80000305 L127.10000038,370.20000458 C113.60000038,356.40000439 94.80000114,347.80000496 74.10000038,347.80000496 C33.20000076,347.70001221 0,381 0,421.79998779 C0,462.59997559 33.20000076,495.89998627 74.09999847,495.89998627 C114.89999771,495.89998627 148.19999695,462.6999855 148.19999695,421.79998779 C148.19999695,405.09998703 142.59999704,389.59998703 133.29999733,377.19998932 L175.49999809,334.99998856 C195.19999886,351.39998817 220.49999809,361.29998779 248.09999657,361.29998779 C310.69999504,361.29998779 361.49999809,310.39998627 361.49999809,247.89998627 C361.39999809,185.39998627 310.49999809,134.49998474 247.89999962,134.49998474 Z"
          style={{ fill: '#d9d9d9', opacity: 1.0 }}
        />
        <foreignObject id="uPDyJTG9EoyekIiH" style={{ width: 361.4, height: 495.9 }}>
          <div
            id="iCLCy9iOp2uA3pir"
            style={{
              clipPath: "path('M74.09999847,486.6000061 C38.39999771,486.6000061 9.29999542,457.6000061 9.29999542,421.80000305 C9.29999542,386 38.29999542,357 74.09999847,357 C109.79999924,357 138.90000153,386 138.90000153,421.80000305 C138.90000153,457.6000061 109.80000114,486.6000061 74.09999847,486.6000061 Z')"
            }}
          >
            <div
              id="zCWRCXBHIGK3vgsv"
              style={{ transform: 'scale(1, 1)', transformOrigin: '74px 422px' }}
            >
              <img
                src="images/b12cc56b60094e7c245c1ac5e7b3494c.png"
                loading="lazy"
                style={{
                  transform: 'translate(9.3px, 357px) rotate(0deg)',
                  transformOrigin: '64.8px 64.8px',
                  width: 129.6,
                  height: 129.6,
                  display: 'block',
                  opacity: 1.0,
                  objectFit: 'fill'
                }}
              />
            </div>
          </div>
        </foreignObject>
        <foreignObject id="IsTuIZkFSICzwEv1" style={{ width: 361.4, height: 495.9 }}>
          <div
            id="kJGTeTPAafyHGdqH"
            style={{
              clipPath: "path('M9.30000019,74.09999847 C9.30000019,38.39999771 38.30000019,9.29999542 74.10000324,9.29999542 C109.80000401,9.29999542 138.90000629,38.29999542 138.90000629,74.09999847 C138.90000629,92.79999924 130.90000629,109.59999847 118.30000591,121.39999771 C117.80000591,121.59999771 117.30000591,121.89999771 116.90000594,122.39999771 C116.60000592,122.69999772 116.40000594,122.99999774 116.20000595,123.29999769 C104.90000576,132.89999807 90.20000595,138.79999769 74.20000595,138.79999769 C38.40000671,138.79999769 9.30000442,109.79999769 9.30000442,74.10000074 Z')"
            }}
          >
            <div
              id="hD8j08mADVfKweYv"
              style={{ transform: 'scale(1, 1)', transformOrigin: '74px 74px' }}
              >
              <img
              src="images/4e30d9ff69df81ad727771bea606d0ea.jpg"
              loading="lazy"
              style={{
              transform: 'translate(9.3px, 9.25px) rotate(0deg)',
              transformOrigin: '64.8px 64.8px',
              width: 129.6,
              height: 129.6,
              display: 'block',
              opacity: 1.0,
              objectFit: 'fill'
              }}
              />
              </div>
              </div>
              </foreignObject>
              <foreignObject id="XJVQp7tzQLC1VGv3" style={{ width: 361.4, height: 495.9 }}>
              <div
              id="wwagQFgBLDYaXOsP"
              style={{
              clipPath: "path('M247.8999939,352.1000061 C190.49999237,352.1000061 143.79999542,305.40000534 143.79999542,248.00000763 C143.79999542,190.60000992 190.49999619,143.90000916 247.8999939,143.90000916 C305.29999542,143.90000916 351.99999237,190.60000992 351.99999237,248.00000763 C351.99999237,305.40000534 305.3999939,352.1000061 247.8999939,352.1000061 Z')"
              }}
              >
              <div
              id="eLosvYzz6VbLS415"
              style={{ transform: 'scale(1, 1)', transformOrigin: '247.5px 248px' }}
              >
              <img
              src="images/dc6657df2b17ca87b9e85ef4e0a642d7.jpg"
              loading="lazy"
              srcSet="images/5e8a5ba3fb46ae268903736e065e069a.jpg 640w, images/dc6657df2b17ca87b9e85ef4e0a642d7.jpg 1280w"
              sizes="(max-width: 375px) 93.67698457vw, (min-width: 375.05px) and (max-width: 480px) 95.58875976vw, (min-width: 480.05px) and (max-width: 768px) 67.23655427vw, (min-width: 768.05px) and (max-width: 1024px) 50.4274157vw, (min-width: 1024.05px) 37.80210372vw"
              style={{
              transform: 'translate(62.83333333px, 143.9px) rotate(0deg)',
              transformOrigin: '185.06666667px 104.1px',
              width: 370.13333333,
              height: 208.2,
              display: 'block',
              opacity: 1.0,
              objectFit: 'fill'
              }}
              />
              </div>
              </div>
              </foreignObject>
              </g>
              </svg>

            </div>
          </div>
        </div>
        <div id="PHhUP4BtIAycRHs1" style={{ zIndex: 3 }}>
          <div id="Ok7dkmBTIrkUKy1k" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
            <div id="cahEgDFKPpsYRpfr" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
              <p id="MBudy59uU8aAQhc4" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.39283973em', textAlign: 'center' }}>
                <span id="rqNM60jadU3swTpz" style={{ color: '#ffffff' }}>Hey !</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>

    </section>
  );
};

export default About;
