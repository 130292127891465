import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import Main_banner from './components/Main_banner';
import Mobile_part from './components/Mobile_part';
import About from './components/About';
import How_to_buy from './components/How_to_buy';
import Community from './components/Community';
import Social_links from './components/Social_links';
import Footer from './components/Footer';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main_banner />
    <Mobile_part />
    <About/>
    <How_to_buy/>
    <Community/>
    <Social_links/> 
    <Footer/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
