import React from 'react';

function How_to_buy() {
  return (

    <section
    id="s1efoeeMZoMsNKD3"
    style={{
      position: 'relative',
      overflow: 'hidden',
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'auto 100rem auto',
      zIndex: 0,
      marginTop: '-1px'
    }}
  >
    <div
      id="xgqbb8WsxTKqSLs3"
      style={{
        gridArea: '1 / 1 / 2 / 4',
        display: 'grid',
        position: 'absolute',
        minHeight: '100%',
        minWidth: '100%'
      }}
    >
      <div id="hOyKEXpfqNd5vOrm" style={{ zIndex: 0 }}>
        <div id="tYQikxH2cOuLFCjj" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
          <div id="kePeALWCT55DMAvC" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
            <div
              id="lQJrn77i5AVjBSUH"
              style={{
                backgroundColor: '#8595a4',
                opacity: 1.0,
                transform: 'scale(1, 1)',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'relative'
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div id="abbylyPZ3Kgp2UCl" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
      <div id="muhvZ596snWeHKfe">
        <div id="ZTDxtG6AkAJfJuxE" style={{ display: 'grid', position: 'relative' }}>
          <div id="mN1OsZ29PDS0aNzI" style={{ zIndex: 2 }}>
            <div id="AI99JzPu5ncPJ0MT" style={{ paddingTop: '24.5%', transform: 'rotate(0deg)' }}>
              <div id="tbzWkobsX48BJJoS" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                <div id="E5aNTsAYGPkGm4fh" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                  <div id="PnRh2IOS6otnyXvw" className="video_container" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                    <div id="CA8y0M51vXB1KUG5" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0 }}>
                      <img src="images/99eb9f28afed0217dbfb084c1a15e9a2.gif" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="LCabzPk0pNXGjIA5" style={{ zIndex: 10 }}>
            <div id="uPJ9mgNxw0qAoUyL" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
              <div id="htUU3el47fXBH6eK" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                <p id="K1PsZfx73ApnbgYW" style={{ color: '#000000', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.38586128em', textAlign: 'center' }}>
                  <span id="xLZ5hBb4GSjof6Vz" style={{ color: '#ffffff' }}>How to buy $HAMI</span><br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="wpi0u3A3qyHIi8fA">
            <div id="jvRYGWKO5FO0erRm" style={{ display: 'grid', position: 'relative' }}>
                <div id="YFm9lEhv6yjngc0Q" style={{ zIndex: 1 }}>
                <div id="H3QeffYINbUyDtPp" style={{ paddingTop: '13.25%', transform: 'rotate(0deg)' }}>
                    <div id="kGuBbKCVGC7UbAQO" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                        <div id="l0YzIc7oJagYuI6p" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                            <div id="SXr9NrzHBZWhk8cH" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                <div id="GNanb1j4qJLpAApk" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                    <img src="images/c74899ab5c7f4eafaa80a36280d37289.svg" alt="4 step infographic" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div id="C1N6ATWzKFWboH5t" style={{ zIndex: 6 }}>
                <div id="GYsaGjyFUuTCg9te" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="LoDizDhG9v6n8rU9" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="BLd5g0W9P3uavjrY" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.38749827em', textAlign: 'center' }}>
                            <span id="Fpvp53Yd2HRKpX2F" style={{ color: '#ffffff' }}>Have or create a </span>
                            <span id="SOeOpzI0LD6teIgD" style={{ color: '#ffffff', fontWeight: 700 }}>Phantom</span>
                            <span id="rNvDYMNOdwZf79jS" style={{ color: '#ffffff' }}> wallet</span>
                        </p>
                    </div>
                </div>
                </div>
                <div id="TT8tIuTQ8KIJphh7" style={{ zIndex: 8 }}>
                <div id="e5VB7iVD45rzPyGI" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="dKjGSpE1WP49Fww6" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="EBYKIcCiKXbtwASF" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.38749827em', textAlign: 'center' }}>
                            <span id="olmxXEIwhMd00Xgc" style={{ color: '#ffffff' }}>Buy </span>
                            <span id="GlMBriivyrXkoW1N" style={{ color: '#ffffff', fontWeight: 700 }}>SOLANA</span>
                            <span id="mjlcNPkFQo1oZgru" style={{ color: '#ffffff' }}> and send it to your wallet</span>
                        </p>
                    </div>
                </div>
                </div>
                <div id="Yg9AERnyt68Vu9CP" style={{ zIndex: 7 }}>
            <div id="jyCSYg0tAoL43dOH" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                <div id="be1izQ0ybCAPVIIi" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="Er1WyU4vinPd1NtD" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.38749827em', textAlign: 'center' }}>
                        <span id="H0ECn2hZgWw04pN9" style={{ color: '#ffffff' }}>Connect it to </span>
                        <a id="I9jpy9xe6b4whvex" target="_blank" rel="noopener" href="https://raydium.io/swap/?inputCurrency=sol&amp;outputCurrency=4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ" style={{ textDecorationLine: 'underline', color: '#ffffff', fontWeight: 700, pointerEvents: 'all' }}>Raydium</a>
                        <span id="ZL8pStLPadnjWgNz" style={{ color: '#ffffff' }}> or </span>
                        <a id="xu68PCCWNNf2O8O7" target="_blank" rel="noopener" href="https://jup.ag/swap/SOL-HAMI_4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ" style={{ textDecorationLine: 'underline', color: '#ffffff', fontWeight: 700, pointerEvents: 'all' }}>Jupiter</a>
                        <br />
                    </p>
                </div>
            </div>
                </div>
                <div id="Mccxk7QmBHnGFvbZ" style={{ zIndex: 9 }}>
            <div id="c3cLhrrW0WgXj0B3" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                <div id="Sd50JZ4yeduY8eOw" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="RESpfgykolls508Y" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.38749827em', textAlign: 'center', letterSpacing: '-0.02em' }}>
                        <span id="RM3biUJb7IC2SCOb" style={{ color: '#ffffff' }}>Swap </span>
                        <span id="RLDAve8iKYgbh23d" style={{ color: '#ffffff', fontWeight: 700 }}>SOL</span><br />
                    </p>
                    <p id="Zr2kxHXv3yRQyFR3" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.38749827em', textAlign: 'center', letterSpacing: '-0.02em' }}>
                        <span id="jMOBs4btFpEoSu5W" style={{ color: '#ffffff' }}>for </span>
                        <span id="jZ7hqPemoi8ykeY9" style={{ color: '#ffffff', fontWeight: 700 }}>$HAMI</span><br />
                    </p>
                    <p id="HF93IozVXSVKuo2p" style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.38749827em', textAlign: 'center', letterSpacing: '-0.02em' }}>
                        <span id="v8dRAoMQhtQ1h0re" style={{ color: '#ffffff' }}>It’s that simple!</span><br />
                    </p>
                </div>
            </div>
                 </div>

            </div>
      </div>
      <div id="EmGUukeZEjH90lA5">
      <div id="DexmZyurgAeRKP4Q" style={{ display: 'grid', position: 'relative' }}>
            <div id="v4hGLD0UUVAYbCzU" style={{ zIndex: 5 }}>
                <div id="w7yKYHX0iPaEG1pQ" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(-29.91865443deg)' }}>
                    <div id="WXKZrXnv5WgoKW2k" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="bteNiZ90aDmnP6Ru" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.35933889em', textAlign: 'center' }}>
                            <span id="QMqf3JYsCV5VEgqC" style={{ color: '#ffffff' }}>Stop ignoring me!</span>
                        </p>
                    </div>
                </div>
            </div>
            <div id="OLOj4RLV5dLezWuu" style={{ zIndex: 4 }}>
                <div id="f7rH1K2ffRAtneHt" style={{ paddingTop: '106.39778056%', transform: 'rotate(0deg)' }}>
                    <div id="dLwfYf2pekASmEW1" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                        <div id="ECucD0MJMVoxYcCJ" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                            <div id="w8vnEB0ABMnH66aJ" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                <div id="MEPCOIlRlvwhpwOV" style={{ width: 'calc(126.00692783% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                    <img src="images/873ccb7d840c9adc297395d8e3ca75cf.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '45.81299509% 50%', transform: 'translate(-45.81299509%, -50%) rotate(0deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    </section>
  );
}

export default How_to_buy;
