import React from "react";

const Footer = () => {
    return (
        <section id="T6zL60iZZFJWJK9L" style={{ position: "relative", overflow: "hidden", display: "grid", alignItems: "center", gridTemplateColumns: "auto 100rem auto", zIndex: 0, marginTop: "-1px" }}>
            <div id="dsJsTC8CkrF8eJHY" style={{ gridArea: "1 / 1 / 2 / 4", display: "grid", position: "absolute", minHeight: "100%", minWidth: "100%" }}>
                <div id="Y7t9ay1EXPge2Ukw" style={{ zIndex: 0 }}>
                    <div id="DSc7JT1JjFPes9Er" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                        <div id="UzPQLCwSPJKRysoQ" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="ZQmN07p2lUSa5U4u" style={{ backgroundColor: "#8595a4", opacity: 1.0, transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="GPCcRBFhLUyTXVLa" style={{ display: "grid", position: "relative", gridArea: "1 / 2 / 2 / 3" }}>
                <div id="rWWnDGlBQWPQVOao" style={{ zIndex: 1 }}>
                    <div id="iaPOlj31OKWEWGoT" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                        <div id="RBz9KxjS1Z2ffGAC" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                            <p id="lATKF93adrvvKS29" style={{ color: "#000000", fontFamily: "YAD5jdnkFxc-0", lineHeight: "1.39285652em", textAlign: "center" }}>
                                <span id="LnASyEPdoWMvR70b" style={{ color: "#ffffff" }}>
                                    HAMI IS CUTE,
                                </span>
                                <br />
                            </p>
                            <p id="tuVDjszEF2ixr4OZ" style={{ color: "#000000", fontFamily: "YAD5jdnkFxc-0", lineHeight: "1.39285652em", textAlign: "center" }}>
                                <span id="hOL5sODXVikQwqp4" style={{ color: "#ffffff" }}>
                                    HAMI IS LOVE.
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="i7D3AkgmqF7YDd04">
                    <div id="hVd4dR5WmWYpR79U" style={{ display: "grid", position: "relative", gridArea: "3 / 2 / 6 / 4" }}>
                        <div id="UihYNVVozH62Xb6p" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 5 / 4" }}>
                            <div id="hceZmN8tfvwzcACn" style={{ zIndex: 5 }}>
                                <div id="FK8Lx7TzuyDAgJSl" style={{ paddingTop: "84.43804035%", transform: "rotate(0deg)" }}>
                                    <div id="n4PxXEvzelcED8IU" style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%" }}>
                                        <div id="KHZNUpU0XmIJEYL0" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                            <div id="tYBMKrPrxNC0sm1d" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div
                                                    id="AKN05CnOGDuf7hrv"
                                                    style={{
                                                        width: "calc(100% * max(1, var(--scale-fill, 1)))",
                                                        height: "calc(100% / min(1, var(--scale-fill, 1)))",
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        opacity: 1.0,
                                                        animation: "pulse 1.5s ease-in-out infinite",
                                                    }}
                                                >
                                                    <img
                                                        src="images/873ccb7d840c9adc297395d8e3ca75cf.png"
                                                        loading="lazy"
                                                        style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="fNsKxSZPI1ctT5Ih" style={{ zIndex: 6 }}>
                                <div id="ZmbR2ZmCam4Rw7oh" style={{ paddingTop: "89%", transform: "rotate(23.87514711deg)" }}>
                                    <div id="pGmTGZo4fHORXUZT" style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%" }}>
                                        <div id="i7Luw6rK6ZCeOy0v" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                            <div id="k2DIYJAdfvDEv4sf" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div
                                                    id="bzM1JcZgfnjF1FVP"
                                                    style={{
                                                        width: "calc(100% * max(1, var(--scale-fill, 1)))",
                                                        height: "calc(100% / min(1, var(--scale-fill, 1)))",
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        opacity: 1.0,
                                                        animation: "pulse 1.5s ease-in-out infinite",
                                                    }}
                                                >
                                                    <img
                                                        src="images/31c0105f470124ec88db7826b2892025.svg"
                                                        alt="Red Heart Shape"
                                                        loading="lazy"
                                                        style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="xUe2LvGc4rHafb8C" style={{ zIndex: 7 }}>
                            <div id="Vgp3XrAwpW61PSbL" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="j8894XATItrUyUXV" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="iZc8o4uBRliQPHQq" style={{ color: "#ffffff", fontFamily: "YAD5jdnkFxc-0", lineHeight: "1.36356819em", textAlign: "center" }}>
                                        <span id="nrRO1MUnPS4WI2Aa" style={{ color: "#ffffff", whiteSpace: "pre-wrap" }}></span>
                                        <span id="BNQjPy1oK3dG1gIn" style={{ color: "#ffffff" }}>
                                            I LOVE YOU HAMIES
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ONsv7Cp55CszxsIh">
                    <div id="FlRLW79ABvWOiIfI" style={{ display: "grid", position: "relative", gridArea: "4 / 3 / 7 / 7" }}>
                        <div id="DArsAEOs7Nu1J6MY" style={{ zIndex: 2 }}>
                            <div id="AbAF3GlnDlk3gZKZ" style={{ paddingTop: "53.125%", transform: "rotate(0deg)" }}>
                                <div id="ik4swvYUREx7TDAM" style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%" }}>
                                    <div id="zMIJZusaX1XMNiGx" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="tSTlyezZhLfikb8W" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div
                                                id="hR4qpPIB9djHYRrd"
                                                style={{
                                                    width: "calc(100% * max(1, var(--scale-fill, 1)))",
                                                    height: "calc(100% / min(1, var(--scale-fill, 1)))",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    opacity: 1.0,
                                                    animation: "pulse 1.5s ease-in-out infinite",
                                                }}
                                            >
                                                <img
                                                    src="images/dbdd89e7b02f386595e00d9ffdb97f00.svg"
                                                    alt="Label Design"
                                                    loading="lazy"
                                                    style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Kxk31fN9AehU3iPQ" style={{ zIndex: 3 }}>
                            <div id="jxlqhhWkBZYYRZD9" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="QeV9FIn5E26vDdnJ" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="wEivA0Jm8oiNWPb6" style={{ color: "#000000", fontFamily: "YAFdJsjyMsM-0", lineHeight: "1.33328889em", textAlign: "center" }}>
                                        <span id="tcYDUxDfk6wVR3LP" style={{ color: "#ffffff", fontWeight: 700 }}>
                                            DISCLOSURE By purchasing $HAMI coin, you agree that you are not purchasing a security or investment contract and you agree to hold the team harmless and not liable for any losses or taxes you may
                                            incur. Although HAMI is a community-driven DeFi Ecosystem and not a registered digital currency, always make sure that you are in compliance with local laws and regulations before you make any
                                            purchase.
                                        </span>
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
