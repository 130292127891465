import React from 'react';

const Community = () => {
    return (
        <section id="fZjOQM2d33UrY4fv" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0, marginTop: '-1px' }}>
            <div id="Sv26MDu74DKEUclR" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
                <div id="pYTsmfFwn7A3eGC3" style={{ zIndex: 0 }}>
                    <div id="BZaJ4S0mzg1z9jRY" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                        <div id="Prq9JATKFvdqw85S" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                            <div id="NKWcYLyD0FYWjDk5" style={{ backgroundColor: '#8595a4', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mxNJFo9ssvhek04X" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
                <div id="uZh2cFDlJ4ryUH10" style={{ zIndex: 5 }}>
                    <div id="qwiGoPMwZjY8Gvco" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                        <div id="xuDSIGzyy1NxhnNZ" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                            <p id="XK42iTj9lgkt7e0H" style={{ color: '#000000', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.39999067em', textAlign: 'center' }}>
                                <span id="YlSVXsUd9Hc43Pnm" style={{ color: '#ffffff' }}>$HAMI COMMUNITY</span><br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="YFiGfXjain5BLO2e">
                    <div id="QrBHvd8A85zLcGmh" style={{ display: 'grid', position: 'relative', gridArea: '3 / 2 / 7 / 4' }}>
                        <div id="yq021q6Bap4jBkx9" style={{ zIndex: 7 }}>
                            <div id="dDRfztGB3cZx2qQX" style={{ paddingTop: '102.25989915%', transform: 'rotate(127.2969632deg)' }}>
                                <div id="eeuvuiX4YixTSYhl" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                    <div id="urtksMiFgVWXAj8W" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="TOxVa7JjTaHFEMdi" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                            <div id="FGZ46c7fJ8Ibgvi5" style={{ width: 'calc(131.41764345% * max(1, var(--scale-fill, 1)))', height: 'calc(108.51417195% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                <img src="images/873ccb7d840c9adc297395d8e3ca75cf.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '38.68889217% 46.07693088%', transform: 'translate(-38.68889217%, -46.07693088%) rotate(0deg)' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="KL9Zs4ibzMFZJHk0" style={{ zIndex: 8 }}>
                            <div id="N8GqHHvdwmluvikn" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(-46.65825202deg)' }}>
                                <div id="P57yOARtTDFX49DY" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                    <p id="u78NM66kO25IqmoD" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.38231521em', textAlign: 'center' }}>
                                        <span id="fgHECRHAtg04tFpr" style={{ color: '#ffffff' }}>JOIN THE HAMILY</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="rQsxIgagkWqcMqFB">
                    <div id="pn7Ai1MkfiNYx7Xy" style={{ display: 'grid', position: 'relative', gridArea: '5 / 5 / 6 / 6' }}>
                        <div id="bFnfHd14GGHkhEbt" style={{ zIndex: 1 }}>
                            <div id="KOCaMMqrrcnYQROf" style={{ paddingTop: '87%', transform: 'rotate(0deg)' }}>
                                <div id="rlxqFoIcmY1t7Tgl" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                    <div id="McaMj24i4rQKgiDU" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="Phr1VJRg1u24C11z" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                            <div id="HE1rDERj3EpLCv3s" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                <img src="images/03bc318fe79efc695ef66854af8ebfd0.svg" alt="Infographic Flow Diagram" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="vAKG07rb0hX3s8iP" style={{ zIndex: 4 }}>
                            <div id="BzRd8gy4jiqDzBwv" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                <div id="yVpDcJfnPuqVwVW3" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                    <p id="iVvWocaTh43Rdudh" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.38749827em', textAlign: 'center' }}>
                                        <span id="heWFM7hSVUD6arWT" style={{ color: '#ffffff' }}>STRONG HAMILY</span></p>
                                </div>
                            </div>
                        </div>
                        <div id="V0w3QBZgyIQh4Pdn" style={{ zIndex: 2 }}>
                            <div id="sRXHFsgU7kRffYpS" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(-29.37510457deg)' }}>
                                <div id="duJTkLzcn4Ros0XD" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                    <p id="jK6aAerBF0amxHNR" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.37499427em', textAlign: 'center' }}>
                                        <span id="Iu4ts4iOOCxSwl4c" style={{ color: '#ffffff' }}>New Holders</span></p>
                                </div>
                            </div>
                        </div>
                        <div id="pAx3yIEM0OxEpZzK" style={{ zIndex: 3 }}>
                            <div id="ReagZpt4TIr8cnRb" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(26.63877113deg)' }}>
                                <div id="zzUTZ4aCsnyphV9m" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                    <p id="DHjIe7AC5lFxINMM" style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.38749827em', textAlign: 'center' }}>
                                        <span id="YuRXcCbc1X94TjCp" style={{ color: '#ffffff' }}>Good vibes and MEME</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Community;


