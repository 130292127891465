import React from 'react';

const Main_banner = () => {
    return (
        <section id="jc7og6kXDLtp55j4"
        style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0 }}>
        <div id="QQbAJtEd4ZwFAp66"
            style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
            <div id="QHefuOxP7WJWn5FZ" style={{ zIndex: 0 }}>
                <div id="qSucrTI4YjwsRqr8"
                    style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="KpqZM4j4ak2vVw18" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                        <div id="tOoUnLuEJzEXsy9L"
                            style={{ backgroundColor: '#ffffff', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            <div id="xvbVm3yd8s5pbkoA"
                                style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100.04882813% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                <img src="images/dc6657df2b17ca87b9e85ef4e0a642d7.jpg" loading="lazy"
                                    srcSet="images/5e8a5ba3fb46ae268903736e065e069a.jpg 640w, images/dc6657df2b17ca87b9e85ef4e0a642d7.jpg 1280w"
                                    sizes="(max-width: 375px) 371.85555556vw, (min-width: 375.05px) and (max-width: 480px) 290.51215278vw, (min-width: 480.05px) and (max-width: 768px) 185.27560764vw, (min-width: 768.05px) and (max-width: 1024px) 138.95670573vw, (min-width: 1024.05px) 100vw"
                                    style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="MyNbdq2rYQKSenrI" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
            <div id="EGybTdCExjaohLb2" style={{ zIndex: 2 }}>
                <div id="C7wJCWehqMXKlhrZ"
                    style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="NyRFzhwrawtcUcS4"
                        style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="dbwtSpXPmabQ2GaH"
                            style={{ color: '#000000', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.39285652em', textAlign: 'center' }}>
                            <span id="df6MXFWWNpKakwlE" style={{ color: '#ffffff' }}>$HAMI</span><br /></p>
                    </div>
                </div>
            </div>
            <div id="VsfZq6zZvEr4cBul" style={{ zIndex: 4 }}>
                <div id="m4SzDOWuMHUQxCAQ"
                    style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="q3yc4aw4Cy2UAQJW"
                        style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="QNyfGbFDUwP2BhtN"
                            style={{ color: '#ffffff', fontFamily: 'YAD5jdnkFxc-0', lineHeight: '1.35933889em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                            <span id="Kx4RciloYjCQqvt3" style={{ color: '#ffffff' }}>SOLSCAN LINK</span><br /></p>
                    </div>
                </div>
            </div>
            <div id="RWug5qr4s9cxwWsu" style={{ zIndex: 3 }}>
                <div id="FmF0e5UuFFg6sY0d"
                    style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="zjhhvk0DDZsLSqRI"
                        style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="xoVCnB9hQFMFxJ6c"
                            style={{ color: '#ffffff', fontFamily: 'YAFdJsjyMsM-0', lineHeight: '1.38748526em', textAlign: 'center' }}>
                            <span id="hoSfQjO6xxmxfmvi" style={{ color: '#ffffff', fontWeight: 700 }}>Welcome</span><span
                                id="zVwPJzJO9Qgbk3To" style={{ color: '#ffffff' }}> to the world of </span><span
                                id="YvXgHxkgH6r1 BAJT" style={{ color: '#ffffff', fontWeight: 700 }}>$HAMI</span><br /></p>
                                </div>
                            </div>
                        </div>
                        <div id="CYxLAnvO0cR6YaHG" style={{ zIndex: 1 }}>
                            <div id="Y35goOEGKG8ZwmQu" style={{ paddingTop: '90.5%', transform: 'rotate(0deg)' }}>
                                <div id="MwVKF94sFr8wun1F" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                    <div id="Stt7zcn0ANxIYdqp" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="rbUGl9wj6sTezZVX" className="video_container"
                                            style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                            <div id="ShRb9XiPvh8iBX1M"
                                                style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0 }}>
                                                <img src="images/01fa1932414d4dbc7058a1c711e845e9.gif" loading="lazy"
                                                    style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default Main_banner;
